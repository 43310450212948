import React from 'react'

import * as icons from './source'

export interface iIconProps {
  icon: keyof typeof icons
  size?: number
  color?: string
}

export default function Icon({ icon, size, color }: iIconProps) {
  const resolveIcon = (iconName: keyof typeof icons, iconColor: string | any) => {
    return icons[iconName](iconColor)
  }

  return (
    <svg
      viewBox="0 0 48 48"
      width={size}
      height={size}
      dangerouslySetInnerHTML={{ __html: resolveIcon(icon, color) }}
    ></svg>
  )
}
