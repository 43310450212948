import React from 'react'

import InputMask from 'react-input-mask'

type types = 'text' | 'number' | 'email' | 'tel' | 'password' | 'date'

type states = string | 'error' | 'success'

export interface iInputProps {
  type?: types
  name?: string
  state?: states
  placeHolder?: string
  label?: string
  value?: string
  icon?: JSX.Element
  helperText?: string
  mask?: string
  className?: string
  onChange: (e: any) => void
  onBlur?: (e: any) => void
  onClick?: any
  iconTransparent?: boolean
  maxLength?: number
  readonly?: string
}

const pattern = 'form-input block w-full p-5 rounded outline-none font-head border-2 border-gray'

const Input: React.FC<iInputProps> = ({
  type = 'text',
  name,
  state,
  placeHolder,
  label,
  value,
  icon,
  helperText,
  mask,
  iconTransparent,
  maxLength,
  onClick = null,
  readonly,
  className,
  ...rest
}) => {
  const defaultClass = () => state === 'error' && 'border-2 border-secondary'

  const getClass = () => {
    return `${defaultClass()} ${pattern} ${className}`
  }

  return (
    <div>
      <label htmlFor={name} className="text-gray-400 font-head text-lg">
        {label}
      </label>
      <div className="relative">
        <InputMask
          type={type}
          className={getClass()}
          placeholder={placeHolder}
          onChange={(e: any) => rest.onChange(e)}
          onBlur={rest.onBlur}
          value={value}
          name={name}
          mask={mask}
          maskplaceholder={null}
          maxLength={maxLength}
          onClick={onClick}
          readOnly={readonly}
        />
        {icon && (
          <div
            className={`p-4 absolute top-0 right-0 pointer-events-none mr-1 mt-1 ${
              iconTransparent ? '' : 'bg-white rounded'
            }`}
          >
            {icon}
          </div>
        )}
        {state && <span className="text-black font-head">{helperText}</span>}
      </div>
    </div>
  )
}

export default Input
