import searchPattern from './searchPattern'

import patterns from '../styles/patterns'

export default function variantType(
  variant: keyof typeof patterns,
  defaultValue: keyof typeof patterns
): typeof patterns | false {
  const helperSearchPattern = searchPattern(variant)

  if (!helperSearchPattern) {
    return searchPattern(defaultValue)
  }

  return helperSearchPattern
}
