export const hero = (fill?: string) => ` 
  <g id="Grupo_1315" data-name="Grupo 1315" transform="translate(-425.42 -105.76)">
    <path id="Caminho_3448" data-name="Caminho 3448" d="M425.42,135.632V109.76l47.044,25.873Z" transform="translate(0 8.792)" fill="#cf2c20" opacity="0.5"/>
    <path id="Caminho_3449" data-name="Caminho 3449" d="M472.464,109.76v25.873L425.42,109.76Z" transform="translate(0 8.792)" fill="#cf2c20" opacity="0.5"/>
    <path id="Caminho_3450" data-name="Caminho 3450" d="M425.42,109.76v25.873l47.044-25.873Z" transform="translate(0 8.792)" fill="#38b5b1" opacity="0.6"/>
    <path id="Caminho_3451" data-name="Caminho 3451" d="M425.42,135.632V109.76l23.506,38.793Z" transform="translate(0 8.792)" fill="#38b5b1" opacity="0.9"/>
    <path id="Caminho_3452" data-name="Caminho 3452" d="M456.308,109.76,432.77,148.553l23.538-12.92Z" transform="translate(16.156 8.792)" fill="#cf2c20" opacity="0.9"/>
    <path id="Caminho_3453" data-name="Caminho 3453" d="M425.42,144.425l23.506,12.92V105.76Z" transform="translate(0 0)" fill="#38b5b1" opacity="0.6"/>
    <path id="Caminho_3454" data-name="Caminho 3454" d="M456.308,144.425l-23.538,12.92V105.76Z" transform="translate(16.156 0)" fill="#cf2c20" opacity="0.4"/>
    <path id="Caminho_3455" data-name="Caminho 3455" d="M425.42,118.552V105.76h23.506l23.538,38.665Z" transform="translate(0 0)" fill="#cf2c20" opacity="0.8"/>
    <path id="Caminho_3456" data-name="Caminho 3456" d="M472.464,118.552V105.76H448.926L425.42,144.425Z" transform="translate(0 0)" fill="#38b5b1" opacity="0.8"/>
    <path id="Caminho_3457" data-name="Caminho 3457" d="M451.709,142.507l11.257-18.517V107.52H451.709v12.792h-13.3V107.52H427.18v16.438l11.225,18.549V131.57h13.3Z" transform="translate(3.869 3.869)" fill="#fff"/>
  </g>`
