import patterns from '../styles/patterns'

export default function SearchPattern(pattern: keyof typeof patterns): false | typeof patterns {
  const resultPattern: any = patterns[pattern]

  if (resultPattern === undefined) {
    return false
  }

  return resultPattern
}
