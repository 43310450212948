import patterns from '../styles/patterns'
import SearchPattern from './searchPattern'

export default function HoverPattern(pattern: keyof typeof patterns) {
  const resultPattern: any = SearchPattern(pattern)

  if (!resultPattern) {
    return ''
  }

  let hover = ' transition-all '

  resultPattern.split(' ').forEach((value: any) => {
    hover += ` hover:${value} `
  })

  return hover
}
