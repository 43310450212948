export const internet = (fill?: string) => `
<g>
    <path
      fill="${fill || '#42368b'}"
      d="M20.69,34.23c1.83-1.83,4.79-1.83,6.61,0s1.83,4.79,0,6.61c-1.83,1.83-4.79,1.83-6.61,0
      C18.87,39.02,18.87,36.06,20.69,34.23C20.69,34.24,20.69,34.24,20.69,34.23"
    />
    <path
      fill="${fill || '#42368b'}"
      d="M32.27,31.62c-0.62,0-1.22-0.25-1.65-0.68c-3.65-3.65-9.57-3.65-13.22,0
      c-0.93,0.9-2.41,0.87-3.31-0.05c-0.88-0.91-0.88-2.35,0-3.25c5.48-5.47,14.35-5.47,19.84,0c0.91,0.91,0.91,2.39,0,3.31
      C33.48,31.37,32.89,31.62,32.27,31.62"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M38.47,25.42c-0.62,0-1.21-0.25-1.65-0.68c-7.07-7.08-18.55-7.08-25.62,0c0,0,0,0,0,0
      c-0.93,0.89-2.41,0.86-3.31-0.08c-0.86-0.9-0.86-2.32,0-3.23c8.91-8.89,23.33-8.89,32.23,0c0.91,0.91,0.91,2.39,0,3.31
      C39.68,25.17,39.08,25.42,38.47,25.42"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M44.66,19.22c-0.62,0-1.21-0.25-1.65-0.68C32.51,8.05,15.5,8.05,4.99,18.53
      c-0.93,0.89-2.41,0.86-3.31-0.08c-0.86-0.9-0.86-2.32,0-3.23c12.33-12.3,32.3-12.3,44.63,0c0.91,0.91,0.91,2.39,0,3.31
      C45.88,18.97,45.28,19.22,44.66,19.22"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M13.4,29.28c0-1.29,1.05-2.34,2.34-2.34c1.29,0,2.34,1.05,2.34,2.34s-1.05,2.34-2.34,2.34
      c0,0,0,0,0,0C14.45,31.61,13.4,30.57,13.4,29.28"
    />
    <path
      fill="${fill || '#42368b'}"
      d="M7.2,23.08c0-1.29,1.05-2.34,2.34-2.34c1.29,0,2.34,1.05,2.34,2.34s-1.05,2.34-2.34,2.34
      c0,0,0,0,0,0C8.25,25.41,7.2,24.37,7.2,23.08"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M1,16.88c0-1.29,1.04-2.34,2.33-2.34c1.29,0,2.34,1.04,2.34,2.34c0,1.29-1.04,2.34-2.34,2.34
      c0,0,0,0,0,0C2.05,19.22,1,18.17,1,16.88C1,16.88,1,16.88,1,16.88"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M29.93,29.28c0-1.29,1.05-2.34,2.34-2.34c1.29,0,2.34,1.05,2.34,2.34s-1.05,2.34-2.34,2.34
      c0,0,0,0,0,0C30.98,31.61,29.93,30.57,29.93,29.28"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M36.13,23.08c0-1.29,1.05-2.34,2.34-2.34c1.29,0,2.34,1.05,2.34,2.34s-1.05,2.34-2.34,2.34
      c0,0,0,0,0,0C37.17,25.41,36.13,24.37,36.13,23.08"
    />
    <path
      fill="${fill || '#42368b'}"
      d="M42.33,16.88c0-1.29,1.05-2.34,2.34-2.34c1.29,0,2.34,1.05,2.34,2.34s-1.05,2.34-2.34,2.34
      c0,0,0,0,0,0C43.37,19.22,42.33,18.17,42.33,16.88C42.33,16.88,42.33,16.88,42.33,16.88"
    />
  </g>
`
