export const fsensina = (fill?: string) => ` 
<defs>
  <linearGradient id="linear-gradient" x1="0.389" y1="0.576" x2="0.749" y2="0.974" gradientUnits="objectBoundingBox">
    <stop offset="0" stop-color="#141513" stop-opacity="0"/>
    <stop offset="1" stop-color="#020303"/>
  </linearGradient>
</defs>
<g id="Grupo_1328" data-name="Grupo 1328" transform="translate(-24 -99.22)">
  <path id="Caminho_3476" data-name="Caminho 3476" d="M47.04,99.23a23.039,23.039,0,0,0-1.9,46,5.645,5.645,0,0,0,.93.08h9.71a5.659,5.659,0,0,0,5.64-5.64h0a5.651,5.651,0,0,0-5.63-5.64H47.06a11.77,11.77,0,1,1,10.43-17.22H45.6a5.13,5.13,0,0,0-5.12,5.12v.66a5.13,5.13,0,0,0,5.12,5.12H61.91a2.5,2.5,0,0,0,2.43-1.61l4.47-8.14.63-1.14A23.062,23.062,0,0,0,47.04,99.23Z" fill="#00a09f"/>
  <path id="Caminho_3477" data-name="Caminho 3477" d="M35.27,122.27a11.769,11.769,0,0,1,22.2-5.45H69.43A23.041,23.041,0,0,0,24,122.27Z" opacity="0.35" fill="url(#linear-gradient)"/>
  <path id="Caminho_3478" data-name="Caminho 3478" d="M68.56,116.83H45.6a5.13,5.13,0,0,0-5.12,5.12v.66a5.13,5.13,0,0,0,5.12,5.12H61.91a2.5,2.5,0,0,0,2.43-1.61l4.47-8.14.63-1.14h-.88Z" fill="#04a0a3"/>
</g>
`
