export const tv = (fill?: string) => `
<g>
    <path
      fill="${fill || '#e50665'}"
      d="M15.32,41.91c-1.2-0.03-2.16-1.02-2.13-2.23c0.02-1.17,0.97-2.11,2.13-2.13
      c1.21,0,2.18-0.98,2.18-2.18s-0.98-2.18-2.18-2.18H2.23c-1.2,0-2.18-0.98-2.18-2.18s0.98-2.18,2.18-2.18c0,0,0,0,0,0h13.09
      c3.61,0,6.55,2.93,6.55,6.54C21.87,38.97,18.94,41.91,15.32,41.91C15.33,41.91,15.32,41.91,15.32,41.91"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M2.23,33.18c-1.2,0-2.18-0.98-2.18-2.18V9.18c0.03-1.2,1.02-2.16,2.23-2.13
      c1.17,0.02,2.11,0.97,2.13,2.13V31C4.41,32.21,3.43,33.18,2.23,33.18"
    />
    <path
      fill="${fill || '#42368b'}"
      d="M45.86,11.36H2.23c-1.2,0.03-2.2-0.93-2.23-2.13C-0.03,8.02,0.93,7.03,2.14,7
      C2.17,7,2.2,7,2.23,7h43.63c1.2,0.03,2.16,1.02,2.13,2.23C47.97,10.4,47.03,11.34,45.86,11.36"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M45.86,33.18c-1.2,0-2.18-0.98-2.18-2.18V9.18c0-1.2,0.98-2.18,2.18-2.18s2.18,0.98,2.18,2.18
      c0,0,0,0,0,0V31C48.04,32.21,47.07,33.18,45.86,33.18"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M32.77,41.91c-3.61,0-6.55-2.93-6.55-6.54c0-3.61,2.93-6.55,6.54-6.55c0,0,0.01,0,0.01,0
      h13.09c1.2,0,2.18,0.98,2.18,2.18s-0.98,2.18-2.18,2.18c0,0,0,0,0,0H32.77c-1.21,0-2.18,0.98-2.18,2.18s0.98,2.18,2.18,2.18
      c1.2,0.03,2.16,1.02,2.13,2.23C34.88,40.94,33.94,41.88,32.77,41.91"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M13.14,39.73c0-1.21,0.98-2.18,2.18-2.18c1.21,0,2.18,0.98,2.18,2.18
      c0,1.21-0.98,2.18-2.18,2.18c0,0,0,0,0,0C14.11,41.91,13.14,40.93,13.14,39.73C13.14,39.73,13.14,39.73,13.14,39.73"
    />
    <path
      fill="${fill || '#42368b'}"
      d="M0.05,31c0-1.21,0.98-2.18,2.18-2.18S4.41,29.79,4.41,31c0,1.21-0.98,2.18-2.18,2.18
      c0,0,0,0,0,0C1.02,33.18,0.05,32.2,0.05,31"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M30.59,39.73c0-1.21,0.98-2.18,2.18-2.18c1.21,0,2.18,0.98,2.18,2.18
      c0,1.21-0.98,2.18-2.18,2.18c0,0,0,0,0,0C31.57,41.91,30.59,40.93,30.59,39.73C30.59,39.73,30.59,39.73,30.59,39.73"
    />
    <path
      fill="${fill || '#42368b'}"
      d="M43.68,31c0-1.21,0.98-2.18,2.18-2.18s2.18,0.98,2.18,2.18c0,1.21-0.98,2.18-2.18,2.18
      c0,0,0,0,0,0C44.66,33.18,43.68,32.2,43.68,31"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M0.05,9.18C0.05,7.98,1.02,7,2.23,7s2.18,0.98,2.18,2.18c0,1.21-0.98,2.18-2.18,2.18
      c0,0,0,0,0,0C1.02,11.36,0.05,10.39,0.05,9.18C0.05,9.18,0.05,9.18,0.05,9.18"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M43.68,9.18c0-1.21,0.98-2.18,2.18-2.18s2.18,0.98,2.18,2.18c0,1.21-0.98,2.18-2.18,2.18
      c0,0,0,0,0,0C44.66,11.36,43.68,10.39,43.68,9.18C43.68,9.18,43.68,9.18,43.68,9.18"
    />
  </g>
`
