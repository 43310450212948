export const pin = (fill?: string) => `
  <g>
    <path
      fill="${fill || '#e50665'}"
      d="M24.33,44c-1.13,0-2.05-0.92-2.05-2.06c0-0.33,0.08-0.65,0.23-0.93l6.13-11.89
      c0.53-1,1.77-1.38,2.77-0.85c0.98,0.52,1.37,1.73,0.87,2.73l-6.13,11.89C25.8,43.57,25.1,44,24.33,44"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M24.34,44c-0.77,0-1.47-0.43-1.83-1.11l-9.86-19.13c-0.52-1.01-0.12-2.24,0.88-2.76
      s2.24-0.12,2.76,0.88l0,0l9.86,19.13c0.52,1.01,0.12,2.25-0.89,2.76C24.98,43.92,24.66,44,24.34,44"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M34.19,24.86c-1.13,0-2.05-0.92-2.05-2.05c0-0.35,0.09-0.69,0.26-1
      c2.47-4.46,0.86-10.08-3.59-12.55c-4.46-2.47-10.08-0.86-12.55,3.59c-1.55,2.79-1.55,6.17,0,8.96c0.55,0.99,0.2,2.24-0.79,2.79
      c-0.99,0.55-2.24,0.2-2.79-0.79C9.1,17.38,11.42,9.26,17.86,5.69c6.44-3.57,14.55-1.25,18.13,5.18c2.24,4.03,2.24,8.92,0,12.94
      C35.63,24.47,34.94,24.87,34.19,24.86"
    />
    <path
      fill="${fill || '#42368b'}"
      d="M20.23,17.33c0-2.26,1.84-4.1,4.1-4.1c2.26,0,4.1,1.84,4.1,4.1c0,2.26-1.84,4.1-4.1,4.1l0,0
      C22.07,21.43,20.23,19.6,20.23,17.33"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M32.15,22.81c0-1.13,0.92-2.05,2.05-2.05c1.13,0,2.05,0.92,2.05,2.05s-0.92,2.05-2.05,2.05
      C33.07,24.86,32.15,23.95,32.15,22.81"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M28.41,30.06c0-1.13,0.92-2.05,2.05-2.05c1.13,0,2.05,0.92,2.05,2.05s-0.92,2.05-2.05,2.05
      c0,0,0,0,0,0C29.33,32.11,28.41,31.19,28.41,30.06"
    />
    <path
      fill="${fill || '#42368b'}"
      d="M22.28,41.95c0-1.13,0.92-2.05,2.05-2.05c1.13,0,2.05,0.92,2.05,2.05
      c0,1.13-0.92,2.05-2.05,2.05C23.2,44,22.28,43.08,22.28,41.95C22.28,41.95,22.28,41.95,22.28,41.95"
    />
  </g>
`
