// eslint-disable-next-line no-use-before-define
import React from 'react'

export interface iLogoProps {
  size?: number
  content?: string
  className?: string
  type?: 'compact'
  color?: string
}

const Logo: React.FC<iLogoProps> = ({ size, content, className, type, color }) => {
  return !type ? (
    <div className={`logo ${className || ''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width={`${size}`} viewBox="0 0 132 66">
        <rect width="132" height="66" transform="translate(0 0)" fill="none" />
        <path
          d="M314.763,304.9a8.343,8.343,0,0,1-3.659-.813,6.454,6.454,0,0,1-2.7-2.423,6.965,6.965,0,0,1-1-3.762V287.055a1.667,1.667,0,1,1,3.334,0v9.679c0,3.4,1.458,5.053,4.458,5.053a4.417,4.417,0,0,0,3.9-1.834,8.794,8.794,0,0,0,1.332-5.124v-7.774a1.667,1.667,0,1,1,3.334,0v17.22h-3.334V302.1a6.771,6.771,0,0,1-5.66,2.8"
          transform="translate(-222.388 -252.049)"
          fill="#43358B"
        />
        <path
          d="M207.838,306.592v-17.22c0-.922,3.334-.922,3.334,0v17.22Z"
          transform="translate(-152.349 -254.367)"
          fill="#43358B"
        />
        <path
          d="M147.057,304.275v-17.22a1.667,1.667,0,1,1,3.334,0v13.876h10.521v3.344Z"
          transform="translate(-109.59 -252.049)"
          fill="#43358B"
        />
        <path
          d="M232.808,310.579v-3.111h7.869a4.513,4.513,0,0,0,3.594-1.59,6.073,6.073,0,0,0,1.406-4.123v-1a8.365,8.365,0,0,1-2.239,1.754,7.8,7.8,0,0,1-3.731.916,9.186,9.186,0,0,1-4.832-1.3,9.03,9.03,0,0,1-3.346-3.6,11.871,11.871,0,0,1-.02-10.373,8.981,8.981,0,0,1,3.306-3.577,9.093,9.093,0,0,1,4.814-1.295,7.986,7.986,0,0,1,3.788.915,8.135,8.135,0,0,1,2.26,1.777v-.4a1.667,1.667,0,1,1,3.334,0v16.17a9.675,9.675,0,0,1-1.048,4.536,7.862,7.862,0,0,1-2.96,3.15,8.321,8.321,0,0,1-4.326,1.138Zm6.823-24.178a5.527,5.527,0,0,0-3.066.876,6.047,6.047,0,0,0-2.125,2.446,8.069,8.069,0,0,0-.778,3.6,8.274,8.274,0,0,0,.779,3.655,6.007,6.007,0,0,0,2.124,2.465,5.521,5.521,0,0,0,3.066.876,5.685,5.685,0,0,0,3.126-.878,5.964,5.964,0,0,0,2.143-2.464,8.789,8.789,0,0,0,0-7.233,5.971,5.971,0,0,0-2.143-2.463,5.687,5.687,0,0,0-3.126-.877"
          transform="translate(-168.17 -250.58)"
          fill="#43358B"
        />
        <path
          d="M383.234,303.426a9.26,9.26,0,0,1-4.871-1.295,9.029,9.029,0,0,1-3.346-3.6,11.023,11.023,0,0,1-1.2-5.177A11.165,11.165,0,0,1,375,288.183a8.954,8.954,0,0,1,3.307-3.6,9.093,9.093,0,0,1,4.814-1.295,8.707,8.707,0,0,1,4.6,1.256,8.908,8.908,0,0,1,3.227,3.416,10.012,10.012,0,0,1,1.166,4.813c0,.495-.013.886-.04,1.161l-.031.317H377.175a7.5,7.5,0,0,0,1.713,4.205,5.237,5.237,0,0,0,4.268,1.857,7.385,7.385,0,0,0,3.684-.829,6.291,6.291,0,0,0,1.74-1.46,1.546,1.546,0,0,1,1.2-.557,1.606,1.606,0,0,1,.893.271,1.589,1.589,0,0,1,.692,1.113,1.647,1.647,0,0,1-.395,1.3,9.506,9.506,0,0,1-2.605,2.085,10.567,10.567,0,0,1-5.129,1.19m5.485-12.051a6.346,6.346,0,0,0-1.731-3.651,5.455,5.455,0,0,0-3.87-1.324,5.2,5.2,0,0,0-3.882,1.51,6.97,6.97,0,0,0-1.856,3.465Z"
          transform="translate(-269.111 -250.578)"
          fill="#43358B"
        />
        <path
          d="M210.915,287.946a1.668,1.668,0,1,0-2.3.523,1.674,1.674,0,0,0,2.3-.523"
          transform="translate(-152.348 -252.049)"
          fill="#f27f2c"
        />
        <path
          d="M24.048,204.011a3.357,3.357,0,0,1-3.175-4.434l8.075-24.02A3.352,3.352,0,1,1,35.3,177.7l-8.075,24.02a3.352,3.352,0,0,1-3.174,2.287"
          transform="translate(-20.695 -173.269)"
          fill="#da0052"
        />
        <path
          d="M24.046,227.807a3.361,3.361,0,0,1-.989-6.57l44.954-13.951a3.359,3.359,0,0,1,1.982,6.419L25.038,227.656a3.339,3.339,0,0,1-.992.151"
          transform="translate(-20.695 -197.066)"
          fill="#f27f2c"
        />
        <path
          d="M54.316,175.207a3.352,3.352,0,1,1-4.454-1.623,3.364,3.364,0,0,1,4.454,1.623"
          transform="translate(-39.854 -173.267)"
          fill="#f27f2c"
        />
        <path
          d="M27.083,256a3.352,3.352,0,1,1-4.454-1.623A3.364,3.364,0,0,1,27.083,256"
          transform="translate(-20.695 -230.037)"
          fill="#43358B"
        />
        <path
          d="M178.7,209.073a3.352,3.352,0,1,1-4.454-1.623,3.364,3.364,0,0,1,4.454,1.623"
          transform="translate(-127.354 -197.064)"
          fill="#da0052"
        />
      </svg>
      {content && (
        <div className="mt-3">
          <span className="px-2">|</span>
          <span className="px-2 font-head">{content}</span>
        </div>
      )}
    </div>
  ) : (
    <div className={`logo ${className || ''}`}>
      <svg width="46" height="27" viewBox="0 0 46 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_80_125)">
          <path
            d="M2.98467 27.0005C2.66071 27.0023 2.33859 26.9519 2.03083 26.8512C1.66116 26.7292 1.31931 26.5358 1.02488 26.282C0.730452 26.0283 0.489238 25.7191 0.315073 25.3724C0.140908 25.0256 0.037219 24.648 0.00994902 24.2613C-0.017321 23.8745 0.0323645 23.4862 0.156159 23.1186L7.34591 2.02205C7.60285 1.27894 8.14453 0.667098 8.85281 0.319998C9.56108 -0.0271016 10.3785 -0.0812977 11.1268 0.169205C11.4964 0.291168 11.8383 0.484573 12.1327 0.738342C12.4271 0.992111 12.6683 1.30125 12.8425 1.64802C13.0167 1.99479 13.1204 2.37239 13.1476 2.75914C13.1749 3.14589 13.1252 3.53419 13.0014 3.90179L5.81168 24.9983C5.61002 25.585 5.22898 26.094 4.72198 26.4542C4.21499 26.8143 3.60749 27.0074 2.98467 27.0065"
            fill="#DA0052"
          />
          <path
            d="M2.98298 27.0059C2.27089 27.011 1.58082 26.7604 1.03916 26.3002C0.497499 25.84 0.140514 25.2009 0.0335881 24.5C-0.0733375 23.7991 0.0769553 23.0834 0.456937 22.4838C0.836919 21.8842 1.42115 21.441 2.10263 21.2354L42.1337 8.98354C42.5115 8.84333 42.914 8.78124 43.3167 8.8011C43.7194 8.82096 44.1138 8.92233 44.4758 9.09902C44.8378 9.27571 45.1598 9.52402 45.4222 9.82877C45.6846 10.1335 45.8819 10.4883 46.0019 10.8715C46.1219 11.2547 46.1622 11.6582 46.1204 12.0574C46.0785 12.4566 45.9553 12.8431 45.7584 13.1934C45.5614 13.5436 45.2948 13.8502 44.9748 14.0944C44.6549 14.3386 44.2882 14.5153 43.8974 14.6138L3.86632 26.8671C3.58019 26.955 3.28244 26.9998 2.98298 27"
            fill="#F27F2C"
          />
          <path
            d="M12.8783 1.70351C13.0438 2.05503 13.1377 2.43566 13.1548 2.82349C13.1719 3.21132 13.1117 3.59867 12.9777 3.96325C12.8437 4.32782 12.6386 4.6624 12.3742 4.94772C12.1098 5.23304 11.7913 5.46347 11.437 5.62571C10.7206 5.95516 9.90255 5.99023 9.16029 5.72333C8.41803 5.45643 7.81134 4.90906 7.47172 4.19986C7.30625 3.84834 7.21228 3.46771 7.19521 3.07988C7.17815 2.69205 7.23833 2.30468 7.3723 1.9401C7.50626 1.57553 7.71136 1.24095 7.97578 0.955631C8.2402 0.670311 8.55871 0.439906 8.91297 0.277665C9.62943 -0.0517889 10.4474 -0.0868805 11.1897 0.180022C11.932 0.446924 12.5387 0.994316 12.8783 1.70351Z"
            fill="#F27F2C"
          />
          <path
            d="M5.68859 22.8016C5.85406 23.1532 5.94803 23.5338 5.9651 23.9216C5.98216 24.3095 5.92197 24.6968 5.78801 25.0614C5.65404 25.426 5.44895 25.7605 5.18453 26.0459C4.92011 26.3312 4.6016 26.5616 4.24734 26.7238C3.53088 27.0533 2.71286 27.0884 1.9706 26.8215C1.22834 26.5546 0.621645 26.0072 0.282027 25.298C0.116555 24.9465 0.0225805 24.5658 0.00551704 24.178C-0.0115464 23.7902 0.0486404 23.4028 0.182606 23.0383C0.316572 22.6737 0.52167 22.3391 0.786089 22.0538C1.05051 21.7685 1.36902 21.538 1.72328 21.3758C2.43974 21.0463 3.25775 21.0113 4.00001 21.2782C4.74228 21.5451 5.34897 22.0924 5.68859 22.8016Z"
            fill={!color ? '#43358B' : color}
          />
          <path
            d="M45.7164 10.5482C45.8819 10.8997 45.9759 11.2804 45.993 11.6682C46.01 12.0561 45.9498 12.4434 45.8158 12.808C45.6819 13.1725 45.4768 13.5071 45.2124 13.7924C44.948 14.0778 44.6294 14.3082 44.2752 14.4704C43.5587 14.7999 42.7407 14.835 41.9984 14.5681C41.2562 14.3012 40.6495 13.7538 40.3099 13.0446C40.1444 12.6931 40.0504 12.3124 40.0333 11.9246C40.0163 11.5368 40.0765 11.1494 40.2104 10.7848C40.3444 10.4203 40.5495 10.0857 40.8139 9.80036C41.0783 9.51504 41.3968 9.28463 41.7511 9.12239C42.4676 8.79294 43.2856 8.75785 44.0279 9.02475C44.7701 9.29165 45.3768 9.83902 45.7164 10.5482Z"
            fill="#DA0052"
          />
        </g>
        <defs>
          <clipPath id="clip0_80_125">
            <rect width="46" height="27" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default Logo
