import React from 'react'
import Link from 'next/link'
import variantType from '../../../helper/variantType'
import hoverPattern from '../../../helper/hoverPattern'
import variants from '../../../styles/patterns'

type types = 'compact' | 'none'

export interface iButtonProps {
  href?: string
  target?: 'blank'
  className?: string
  type?: types
  variant: keyof typeof variants
  hover?: keyof typeof variants
  icon?: JSX.Element
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  props?: React.PropsWithChildren<iButtonProps>
  flat?: boolean
}

const Button: React.FC<iButtonProps> = ({ href, target, type, variant, hover, icon, className, flat, onClick, ...rest }) => {
  const defaultClass = () => {
    if (type === 'compact') {
      return 'px-3 py-1 rounded'
    }

    if (type === 'none') {
      return ''
    }
   
    return `p-5 ${flat ? '' : 'rounded'}`
  }
  

  const getClass = () => {
    return `${defaultClass()} ${type !== 'none' && variantType(variant, 'primary')} ${hoverPattern(hover)}`
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!onClick) {
      return
    }

    if (variant === 'disabled') {
      e.preventDefault()
    }

    return onClick(e)
  }

  const button = (
    <button
      className={`font-head relative flex items-center justify-center ${getClass()} ${className || ''}`}
      onClick={e => handleClick(e)}
      aria-label=""
    >
      {icon && <div className="w-4 mr-5">{icon}</div>}
      {rest.children || ''}
    </button>
  )

  return href ? (
    <Link href={href}>
      <a aria-label="Botão de ação" target={target && target}>
        {button}
      </a>
    </Link>
  ) : (
    button
  )
}

export default Button
