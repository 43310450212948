const patterns = {
  primary: 'bg-primary border-primary text-white',
  secondary: 'bg-secondary border-secondary text-white',
  tertiary: 'bg-tertiary border-tertiary text-white',
  disabled: 'bg-gray-200 border-gray-300 text-gray-400 font-semibold cursor-not-allowed',
  white: 'bg-white border-white text-black',
  green: 'bg-green-400 border-green text-white',
  text: 'bg-transparent border-transparent text-black',
  gray: 'bg-gray border-gray text-black'
}

export default patterns
