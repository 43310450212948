export const movel = (fill?: string) => `
<path d="M34.036 44.3106H12.805C12.2688 44.2719 11.7674 44.0368 11.4015 43.6525C11.0356 43.2683 10.8323 42.7633 10.8323 42.2389C10.8323 41.7146 11.0356 41.2096 11.4015 40.8253C11.7674 40.4411 12.2688 40.206 12.805 40.1673H34.036C34.3282 40.1462 34.6217 40.1841 34.8982 40.2786C35.1746 40.3732 35.4282 40.5223 35.6428 40.7168C35.8575 40.9112 36.0287 41.1467 36.1458 41.4086C36.2629 41.6705 36.3234 41.9532 36.3234 42.2389C36.3234 42.5247 36.2629 42.8073 36.1458 43.0692C36.0287 43.3311 35.8575 43.5667 35.6428 43.7611C35.4282 43.9555 35.1746 44.1047 34.8982 44.1993C34.6217 44.2938 34.3282 44.3317 34.036 44.3106Z"
fill="${fill || '#E50063'}"/>
<path d="M12.9565 44.3162C12.3931 44.3162 11.8528 44.0941 11.4545 43.6987C11.0561 43.3033 10.8323 42.7671 10.8323 42.2079V6.26295C10.8323 5.7038 11.0561 5.16754 11.4545 4.77216C11.8528 4.37678 12.3931 4.15466 12.9565 4.15466C13.5199 4.15466 14.0602 4.37678 14.4586 4.77216C14.857 5.16754 15.0808 5.7038 15.0808 6.26295V42.1732C15.0808 42.7323 14.857 43.2686 14.4586 43.664C14.0602 44.0593 13.5199 44.2815 12.9565 44.2815"  fill="${
  fill || '#F19340'
}" />
<path d="M32.075 42.2503C32.0727 41.8385 32.1956 41.4353 32.4282 41.0919C32.6607 40.7486 32.9923 40.4806 33.3809 40.3219C33.7696 40.1633 34.1976 40.1212 34.6108 40.201C35.0239 40.2808 35.4035 40.4788 35.7013 40.77C35.999 41.0612 36.2016 41.4325 36.2832 41.8365C36.3648 42.2405 36.3217 42.6591 36.1595 43.0391C35.9973 43.4192 35.7233 43.7435 35.3722 43.9709C35.0211 44.1983 34.6088 44.3185 34.1876 44.3162C33.6283 44.3133 33.0926 44.0946 32.6971 43.7078C32.3016 43.321 32.078 42.7973 32.075 42.2503"  fill="${
  fill || '#F19340'
}" />
<path d="M20.7456 33.941C20.7433 33.5291 20.8663 33.1259 21.0988 32.7826C21.3314 32.4392 21.663 32.1712 22.0516 32.0126C22.4402 31.854 22.8683 31.8119 23.2814 31.8917C23.6946 31.9715 24.0741 32.1695 24.3719 32.4607C24.6697 32.7519 24.8723 33.1231 24.9539 33.5271C25.0355 33.9311 24.9924 34.3498 24.8302 34.7298C24.668 35.1099 24.3939 35.4342 24.0428 35.6616C23.6917 35.889 23.2794 36.0092 22.8583 36.0069C22.5804 36.0084 22.305 35.956 22.048 35.8527C21.791 35.7494 21.5574 35.5973 21.361 35.4052C21.1645 35.213 21.0089 34.9847 20.9033 34.7333C20.7977 34.482 20.7441 34.2127 20.7456 33.941"  fill="${
  fill || '#F19340'
}"/>
<path d="M10.8323 42.2389C10.8323 41.8281 10.9568 41.4265 11.1902 41.0848C11.4237 40.7432 11.7554 40.477 12.1436 40.3198C12.5317 40.1625 12.9589 40.1214 13.3709 40.2015C13.783 40.2817 14.1615 40.4795 14.4586 40.7701C14.7557 41.0606 14.958 41.4307 15.04 41.8337C15.1219 42.2366 15.0799 42.6543 14.9191 43.0339C14.7583 43.4135 14.486 43.7379 14.1367 43.9662C13.7873 44.1944 13.3767 44.3163 12.9565 44.3163C12.3931 44.3163 11.8528 44.0974 11.4545 43.7078C11.0561 43.3182 10.8323 42.7899 10.8323 42.2389Z"  fill="${
  fill || '#FFF'
}" />
<path d="M34.036 8.30363H12.805C12.2688 8.2649 11.7674 8.02983 11.4015 7.64559C11.0356 7.26135 10.8323 6.75636 10.8323 6.23199C10.8323 5.70761 11.0356 5.20262 11.4015 4.81838C11.7674 4.43414 12.2688 4.19908 12.805 4.16034H34.036C34.3282 4.13923 34.6217 4.17713 34.8982 4.27168C35.1746 4.36622 35.4282 4.51538 35.6428 4.70981C35.8575 4.90425 36.0287 5.13977 36.1458 5.40168C36.2629 5.6636 36.3234 5.94626 36.3234 6.23199C36.3234 6.51771 36.2629 6.80037 36.1458 7.06229C36.0287 7.3242 35.8575 7.55972 35.6428 7.75416C35.4282 7.94859 35.1746 8.09775 34.8982 8.19229C34.6217 8.28684 34.3282 8.32474 34.036 8.30363Z"  fill="${
  fill || '#FFF'
}" />
<path d="M10.8323 6.23198C10.8323 5.82113 10.9568 5.4195 11.1902 5.07789C11.4237 4.73627 11.7554 4.47002 12.1436 4.3128C12.5317 4.15557 12.9589 4.11442 13.3709 4.19458C13.783 4.27473 14.1615 4.47257 14.4586 4.76309C14.7557 5.05361 14.958 5.42377 15.04 5.82673C15.1219 6.22969 15.0799 6.64736 14.9191 7.02694C14.7583 7.40652 14.486 7.73095 14.1367 7.95921C13.7873 8.18747 13.3767 8.30931 12.9565 8.30931C12.3931 8.30931 11.8528 8.09045 11.4545 7.70088C11.0561 7.3113 10.8323 6.78292 10.8323 6.23198"  fill="${
  fill || '#E50063'
}" />
<path d="M34.1934 36.0069C33.6315 36.0069 33.0927 35.7828 32.6954 35.3837C32.2982 34.9847 32.075 34.4435 32.075 33.8792V6.13609C32.1147 5.5975 32.3557 5.09392 32.7497 4.72641C33.1437 4.3589 33.6615 4.15466 34.1992 4.15466C34.7369 4.15466 35.2547 4.3589 35.6487 4.72641C36.0427 5.09392 36.2837 5.5975 36.3235 6.13609V33.8675C36.3235 34.1479 36.2682 34.4255 36.1611 34.6844C36.0539 34.9434 35.8968 35.1785 35.6989 35.3762C35.5009 35.574 35.266 35.7304 35.0076 35.8366C34.7492 35.9429 34.4726 35.9968 34.1934 35.9953"  fill="${
  fill || '#E50063'
}" />
<path d="M32.075 33.941C32.0727 33.5291 32.1956 33.1259 32.4282 32.7826C32.6607 32.4392 32.9923 32.1712 33.3809 32.0126C33.7696 31.854 34.1976 31.8119 34.6108 31.8917C35.0239 31.9715 35.4035 32.1695 35.7013 32.4607C35.999 32.7519 36.2016 33.1231 36.2832 33.5271C36.3648 33.9311 36.3217 34.3498 36.1595 34.7298C35.9973 35.1099 35.7233 35.4342 35.3722 35.6616C35.0211 35.889 34.6088 36.0092 34.1876 36.0069C33.6273 36.0069 33.09 35.7893 32.6938 35.4018C32.2976 35.0144 32.075 34.4889 32.075 33.941"  fill="${
  fill || '#FFF'
}" />
<path d="M32.075 6.2433C32.0727 5.83149 32.1956 5.42831 32.4282 5.08497C32.6607 4.74162 32.9923 4.4736 33.3809 4.31496C33.7696 4.15633 34.1976 4.11424 34.6108 4.19403C35.0239 4.27382 35.4035 4.47189 35.7013 4.76309C35.999 5.05429 36.2016 5.42548 36.2832 5.82949C36.3648 6.2335 36.3217 6.65213 36.1595 7.03217C35.9973 7.41221 35.7233 7.73652 35.3722 7.96391C35.0211 8.19131 34.6088 8.31152 34.1876 8.30928C33.6283 8.3063 33.0926 8.08766 32.6971 7.70086C32.3016 7.31406 32.078 6.79031 32.075 6.2433"  fill="${
  fill || '#F19340'
}"/>
`
