import React from 'react'

interface AreaLoadingProps {
  width: string
  height: string
}

const AreaLoading: React.FC<AreaLoadingProps> = ({ width, height }) => {
  return <div className={`bg-dark-gray animate-pulse rounded ${width} ${height}`}></div>
}

export default AreaLoading
