export const star = (fill?: string) => `
<g>
    <path
      fill="${fill || '#E50665'}"
      d="M36.29,45.06c-0.44,0-0.87-0.14-1.23-0.4L24,36.61l-11.07,8.04c-0.98,0.62-2.28,0.33-2.9-0.65
      c-0.57-0.89-0.38-2.07,0.44-2.75l12.3-8.93c0.73-0.53,1.73-0.53,2.46,0l12.3,8.94c0.93,0.69,1.13,2,0.45,2.94
      C37.58,44.74,36.95,45.05,36.29,45.06"
    />
    <path
      fill="${fill || '#F39441'}"
      d="M11.69,45.05c-1.16,0-2.1-0.95-2.09-2.11c0-0.22,0.03-0.43,0.1-0.64l4.23-13.01L2.86,21.25
      c-0.89-0.74-1.01-2.07-0.27-2.96c0.67-0.81,1.84-0.99,2.73-0.43l12.3,8.94c0.73,0.53,1.04,1.48,0.76,2.34l-4.7,14.46
      C13.4,44.46,12.6,45.05,11.69,45.05"
    />
    <path
      fill="${fill || '#42368B'}"
      d="M19.3,21.65L19.3,21.65H4.1c-1.16,0.08-2.16-0.79-2.24-1.95s0.79-2.16,1.95-2.24
      c0.1-0.01,0.19-0.01,0.29,0h13.68l4.23-13.01c0.43-1.08,1.65-1.6,2.73-1.18c0.98,0.39,1.52,1.45,1.26,2.48l-4.7,14.46
      C21.01,21.07,20.21,21.66,19.3,21.65"
    />
    <path
      fill="${fill || '#E50665'}"
      d="M43.9,21.66L43.9,21.66H28.7c-0.91,0-1.71-0.58-1.99-1.45l-4.7-14.46
      c-0.29-1.12,0.39-2.27,1.52-2.55c1.02-0.26,2.08,0.28,2.47,1.26l4.23,13.02H43.9c1.16-0.08,2.16,0.79,2.24,1.95
      s-0.79,2.16-1.95,2.24C44.09,21.66,44,21.66,43.9,21.66"
    />
    <path
      fill="${fill || '#F39441'}"
      d="M31.6,30.59c-1.16,0-2.1-0.94-2.1-2.09c0-0.67,0.32-1.3,0.86-1.7l12.3-8.93
      c0.94-0.68,2.25-0.47,2.93,0.46s0.47,2.25-0.46,2.93l0,0l-12.3,8.93C32.47,30.45,32.04,30.59,31.6,30.59"
    />
    <path
      fill="${fill || '#42368B'}"
      d="M44.55,21.58c-1.1,0.36-2.28-0.25-2.64-1.35c-0.36-1.1,0.25-2.28,1.35-2.64
      c1.1-0.36,2.28,0.25,2.64,1.35c0,0,0,0,0,0C46.25,20.04,45.65,21.22,44.55,21.58"
    />
    <path
      fill="${fill || '#F39441'}"
      d="M24.65,7.09c-1.1,0.36-2.29-0.24-2.65-1.34c-0.36-1.1,0.24-2.29,1.34-2.65
      c1.1-0.36,2.29,0.24,2.65,1.34c0,0,0,0.01,0,0.01C26.35,5.55,25.75,6.73,24.65,7.09C24.65,7.09,24.65,7.09,24.65,7.09"
    />
    <path
      fill="${fill || '#F39441'}"
      d="M36.94,44.95c-1.1,0.36-2.28-0.25-2.64-1.35c-0.36-1.1,0.25-2.28,1.35-2.64
      c1.1-0.36,2.28,0.25,2.64,1.35c0,0,0,0,0,0C38.64,43.41,38.04,44.59,36.94,44.95C36.94,44.95,36.94,44.95,36.94,44.95"
    />
    <path
      fill="${fill || '#E50665'}"
      d="M32.25,30.49c-1.1,0.36-2.28-0.25-2.64-1.35c-0.36-1.1,0.25-2.28,1.35-2.64
      c1.1-0.36,2.28,0.25,2.64,1.35c0,0,0,0,0,0C33.95,28.95,33.35,30.13,32.25,30.49C32.25,30.49,32.25,30.49,32.25,30.49"
    />
    <path
      fill="${fill || '#42368B'}"
      d="M12.34,44.95c-1.1,0.36-2.29-0.24-2.65-1.34c-0.36-1.1,0.24-2.29,1.34-2.65
      c1.1-0.36,2.29,0.24,2.65,1.34c0,0,0,0.01,0,0.01C14.04,43.41,13.44,44.59,12.34,44.95"
    />
    <path
      fill="${fill || '#E50665'}"
      d="M4.74,21.53c-1.1,0.36-2.28-0.25-2.64-1.35c-0.36-1.1,0.25-2.28,1.35-2.64
      c1.1-0.36,2.28,0.25,2.64,1.35c0,0,0,0,0,0C6.45,19.99,5.85,21.17,4.74,21.53C4.75,21.53,4.74,21.53,4.74,21.53"
    />
  </g>
`
