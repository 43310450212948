import React from 'react'

import variantType from '../../../helper/variantType'

import variants from '../../../styles/patterns'

export interface iBoxProps {
  variant: keyof typeof variants
  className?: string
}

const pattern = 'lg:rounded p-5 lg:mb-5 lg:mt-5 border-b-2 border-gray lg:border-none'

const Box: React.FC<iBoxProps> = ({ variant, className, ...rest }) => {
  const getClass = () => {
    return `${variantType(variant, 'primary')} ${pattern} ${className}`
  }

  return <section className={getClass()}>{rest.children || ''}</section>
}

export default Box
