export const cart = (fill?: string) => `
<g>
  <path
    fill="${fill || '#e50665'}"
    d="M10.44,13.8c-1.3,0-2.36-1.06-2.36-2.36c0-2.6-2.11-4.72-4.72-4.72C2.06,6.72,1,5.66,1,4.36
    C1,3.06,2.06,2,3.36,2l0,0c5.21,0,9.43,4.23,9.43,9.43C12.79,12.74,11.74,13.79,10.44,13.8"
  />
  <path
    fill="${fill || '#FFF'}"
    d="M31.03,32.67H12.8c-1.19,0-2.19-0.89-2.34-2.07l-1.18-9.43c-0.16-1.29,0.76-2.47,2.05-2.63
    s2.47,0.76,2.63,2.05l0.92,7.37h16.15c2.95-0.01,5.58-1.84,6.63-4.59l4.78-12.75c0.44-1.23,1.78-1.87,3.01-1.44s1.87,1.78,1.44,3.01
    c-0.01,0.03-0.02,0.05-0.03,0.08l-4.78,12.75C40.33,29.61,35.94,32.65,31.03,32.67"
  />
  <path
    fill="${fill || '#f39441'}"
    d="M44.64,13.8h-34.2c-1.3,0-2.36-1.05-2.36-2.35s1.05-2.36,2.35-2.36c0,0,0.01,0,0.01,0h34.2
    c1.3,0,2.36,1.06,2.35,2.36C47,12.74,45.94,13.8,44.64,13.8"
  />
  <path
    fill="${fill || '#e50665'}"
    d="M10.44,41.51c0-2.28,1.85-4.13,4.13-4.13s4.13,1.85,4.13,4.13c0,2.28-1.85,4.13-4.13,4.13
    C12.28,45.64,10.44,43.79,10.44,41.51C10.44,41.51,10.44,41.51,10.44,41.51"
  />
  <path
    fill="${fill || '#e50665'}"
    d="M28.13,41.51c0-2.28,1.85-4.13,4.13-4.13s4.13,1.85,4.13,4.13c0,2.28-1.85,4.13-4.13,4.13
    C29.98,45.64,28.13,43.79,28.13,41.51C28.13,41.51,28.13,41.51,28.13,41.51"
  />
  <path
    fill="${fill || '#f39441'}"
    d="M3.36,6.72C2.06,6.72,1,5.66,1,4.36S2.05,2,3.36,2c1.3,0,2.36,1.06,2.36,2.36c0,0,0,0,0,0
    C5.72,5.66,4.66,6.72,3.36,6.72"
  />
  <path
    fill="${fill || '#42368b'}"
    d="M10.44,13.8c-1.3,0-2.36-1.06-2.36-2.36c0-1.3,1.06-2.36,2.36-2.36c1.3,0,2.36,1.06,2.36,2.36
    S11.74,13.8,10.44,13.8C10.44,13.8,10.44,13.8,10.44,13.8"
  />
  <path
    fill="${fill || '#e50665'}"
    d="M44.64,13.8c-1.3,0-2.36-1.06-2.36-2.36s1.06-2.36,2.36-2.36c1.3,0,2.36,1.06,2.36,2.36
    c0,0,0,0,0,0C47,12.74,45.94,13.8,44.64,13.8"
  />
  <path
    fill="${fill || '#f39441'}"
    d="M11.62,23.23c-1.3,0-2.36-1.06-2.36-2.36s1.06-2.36,2.36-2.36c1.3,0,2.36,1.06,2.36,2.36
    c0,0,0,0,0,0C13.97,22.17,12.92,23.23,11.62,23.23L11.62,23.23"
  />
</g>
`
