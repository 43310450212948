export const fixo = (fill?: string) => `
  <g>
    <path
      fill="${fill || '#e50665'}"
      d="M28.29,11.28H10.85c-1.18,0.03-2.16-0.91-2.18-2.09C8.64,8,9.57,7.03,10.75,7
      c0.03,0,0.06,0,0.09,0h17.45c1.18,0.03,2.12,1,2.09,2.18C30.36,10.33,29.44,11.25,28.29,11.28"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M2.71,24.1c-0.98,0-1.83-0.67-2.07-1.62l-0.3-1.22c-0.77-3.08-0.2-6.34,1.55-8.98
      C4.08,9.01,7.74,7.03,11.68,7h0.02c1.18,0.02,2.12,0.99,2.1,2.17c-0.02,1.15-0.94,2.07-2.08,2.1c-2.52,0.02-4.87,1.29-6.26,3.39
      c-1.09,1.64-1.44,3.66-0.96,5.57l0.3,1.22c0.29,1.15-0.41,2.31-1.56,2.59C3.06,24.08,2.89,24.1,2.71,24.1"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M2.71,24.1c-1.18,0-2.14-0.96-2.13-2.14c0-1.08,0.8-1.98,1.87-2.12l8.44-1.07
      c1.17-0.18,2.26,0.63,2.43,1.8s-0.63,2.26-1.8,2.43c-0.03,0-0.07,0.01-0.1,0.01l-8.44,1.07C2.89,24.1,2.8,24.1,2.71,24.1"
    />
    <path
      fill="${fill || '#42368b'}"
      d="M45.29,24.1c-1.18,0-2.14-0.96-2.14-2.14c0-0.17,0.02-0.35,0.06-0.51l0.3-1.22
      c0.48-1.91,0.13-3.93-0.97-5.57c-1.4-2.1-3.74-3.37-6.26-3.39c-1.18-0.03-2.11-1.01-2.08-2.19c0.03-1.15,0.96-2.06,2.1-2.08h0.02
      c3.94,0.03,7.6,2.01,9.78,5.29c1.76,2.64,2.32,5.9,1.55,8.98l-0.3,1.22C47.12,23.43,46.27,24.1,45.29,24.1"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M34.17,9.14c0-1.18,0.96-2.14,2.14-2.14c1.18,0,2.14,0.96,2.14,2.14s-0.96,2.14-2.14,2.14
      c0,0,0,0,0,0C35.13,11.27,34.17,10.32,34.17,9.14"
    />
    <path
      fill="${fill || '#42368B'}"
      d="M45.29,24.1c-0.09,0-0.18-0.01-0.27-0.02l-8.44-1.07c-1.17-0.12-2.03-1.17-1.91-2.35
      s1.17-2.03,2.35-1.91c0.03,0,0.07,0.01,0.1,0.01l8.44,1.07c1.17,0.15,2,1.22,1.85,2.39C47.27,23.3,46.37,24.1,45.29,24.1"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M31.69,40.13H16.34c-5.08,0-9.2-4.11-9.2-9.2c0-0.65,0.07-1.3,0.2-1.93l1.75-8.54
      c0.22-1.16,1.34-1.92,2.5-1.71c1.16,0.22,1.92,1.34,1.71,2.5c0,0.02-0.01,0.05-0.01,0.07l-1.76,8.56c-0.58,2.66,1.1,5.28,3.76,5.86
      c0.35,0.08,0.7,0.11,1.05,0.11h15.35c1.11,0,2.19-0.38,3.06-1.07c0.01-0.01,0.01-0.01,0.02-0.02c1.46-1.16,2.14-3.06,1.74-4.88
      l-1.76-8.57c-0.24-1.16,0.51-2.29,1.66-2.52c1.16-0.24,2.29,0.51,2.52,1.66l0,0l1.76,8.55c0.74,3.4-0.52,6.92-3.24,9.08l-0.03,0.02
      C35.79,39.42,33.77,40.13,31.69,40.13"
    />
    <path
      fill="${fill || '#e50665'}"
      d="M34.71,20.9c0-1.18,0.96-2.14,2.14-2.14s2.14,0.96,2.14,2.14s-0.96,2.14-2.14,2.14
      c0,0,0,0,0,0C35.66,23.03,34.71,22.08,34.71,20.9C34.71,20.9,34.71,20.9,34.71,20.9"
    />
    <path
      fill="${fill || '#42368B'}"
      d="M13.29,20.9c0-1.18-0.96-2.14-2.14-2.14s-2.14,0.96-2.14,2.14s0.96,2.14,2.14,2.14
      c0,0,0,0,0,0C12.34,23.03,13.29,22.08,13.29,20.9C13.29,20.9,13.29,20.9,13.29,20.9"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M17.6,20.9c0-1.18,0.96-2.14,2.14-2.14s2.14,0.96,2.14,2.14c0,1.18-0.96,2.13-2.14,2.14
      C18.56,23.03,17.61,22.08,17.6,20.9C17.61,20.9,17.61,20.9,17.6,20.9"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M17.6,29.44c0-1.18,0.96-2.14,2.14-2.14s2.14,0.96,2.14,2.14c0,1.18-0.96,2.14-2.14,2.14
      C18.56,31.58,17.61,30.62,17.6,29.44"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M26.16,20.9c0-1.18,0.96-2.14,2.14-2.14s2.14,0.96,2.14,2.14s-0.96,2.14-2.14,2.14l0,0
      C27.11,23.03,26.16,22.08,26.16,20.9C26.16,20.9,26.16,20.9,26.16,20.9"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M26.16,29.44c0-1.18,0.96-2.14,2.14-2.14s2.14,0.96,2.14,2.14s-0.96,2.14-2.14,2.14l0,0
      C27.11,31.58,26.15,30.62,26.16,29.44"
    />
    <path
      fill="${fill || '#f39441'}"
      d="M26.16,9.14c0-1.18,0.96-2.14,2.14-2.14c1.18,0,2.14,0.96,2.14,2.14s-0.96,2.14-2.14,2.14
      c0,0,0,0,0,0C27.11,11.27,26.16,10.32,26.16,9.14"
    />
  </g>
`
