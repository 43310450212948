export const plus = (fill?: string) => `
  <g>
    <path
      fill="${fill || '#E50665'}"
      d="M24.01,27.33H7.34C5.5,27.33,4,25.84,4,24c0-1.84,1.49-3.33,3.33-3.33h16.67
      c1.84,0,3.33,1.49,3.33,3.33C27.34,25.84,25.85,27.33,24.01,27.33"
    />
    <path
      fill="${fill || '#F39441'}"
      d="M24,27.33c-1.84,0-3.33-1.49-3.33-3.33V7.34C20.67,5.5,22.16,4,24,4
      c1.84,0,3.33,1.49,3.33,3.33V24C27.33,25.84,25.84,27.33,24,27.33C24,27.34,24,27.34,24,27.33"
    />
    <path
      fill="${fill || '#E50665'}"
      d="M40.67,27.33H23.99c-1.84,0-3.33-1.49-3.33-3.33c0-1.84,1.49-3.33,3.33-3.33l0,0h16.67
      c1.84,0,3.33,1.49,3.33,3.33C44,25.84,42.51,27.33,40.67,27.33"
    />
    <path
      fill="${fill || '#F39441'}"
      d="M24,44c-1.84,0-3.33-1.49-3.33-3.33V24c0-1.84,1.49-3.33,3.33-3.33
      c1.84,0,3.33,1.49,3.33,3.33v16.67C27.33,42.51,25.84,44,24,44C24,44,24,44,24,44"
    />
    <path
      fill="${fill || '#42368B'}"
      d="M27.33,24c0,1.84-1.49,3.33-3.33,3.33c-1.84,0-3.33-1.49-3.33-3.33
      c0-1.84,1.49-3.33,3.33-3.33C25.84,20.67,27.33,22.16,27.33,24C27.33,24,27.33,24,27.33,24"
    />
    <path
      fill="${fill || '#E50665'}"
      d="M24,44c-1.84,0-3.33-1.49-3.33-3.33s1.49-3.33,3.33-3.33c1.84,0,3.33,1.49,3.33,3.33
      C27.33,42.51,25.84,44,24,44C24,44,24,44,24,44"
    />
    <path
      fill="${fill || '#E50665'}"
      d="M24,10.67c-1.84,0-3.33-1.49-3.33-3.33S22.16,4,24,4s3.33,1.49,3.33,3.33c0,0,0,0,0,0
      C27.33,9.18,25.84,10.67,24,10.67C24,10.67,24,10.67,24,10.67"
    />
    <path
      fill="${fill || '#F39441'}"
      d="M4,24c0-1.84,1.49-3.33,3.33-3.33s3.33,1.49,3.33,3.33c0,1.84-1.49,3.33-3.33,3.33
      C5.49,27.34,4,25.84,4,24C4,24,4,24,4,24"
    />
    <path
      fill="${fill || '#F39441'}"
      d="M37.33,24c0-1.84,1.49-3.33,3.33-3.33S44,22.16,44,24c0,1.84-1.49,3.33-3.33,3.33
      C38.83,27.34,37.33,25.84,37.33,24"
    />
  </g>
`
